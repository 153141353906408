import { NgFor } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent, CheckboxComponent, DropdownModule, IconComponent, SearchInputComponent } from '@sb/ui';
import { TableModule } from 'primeng/table';

import { SbDropdownModule } from '../../../../+authenticated/shared/dropdown/sb-dropdown.module';
import { SharedModule } from '../../../shared.module';
import { SelectAbsenceConflictComponent } from './employee-select-item/conflicts/absence/select-absence-conflict.component';
import { SelectAvailabilityConflictComponent } from './employee-select-item/conflicts/availabilities/select-availability-conflict.component';
import { SelectScheduleConflictComponent } from './employee-select-item/conflicts/schedules/select-schedule-conflict.component';
import { SelectSkillConflictComponent } from './employee-select-item/conflicts/skills/select-skill-conflict.component';
import { SbEmployeeSelectComponent } from './employee-select-item/sb-employee-select.component';
import { SbTableSelectComponent } from './sb-table-select.component';

const declarations = [SbTableSelectComponent];
@NgModule({
  imports: [
    TableModule,
    SharedModule,
    SbDropdownModule,
    SearchInputComponent,
    CheckboxComponent,
    IconComponent,
    DropdownModule,
    ButtonComponent,
    NgFor,
  ],
  declarations: [
    declarations,
    SbEmployeeSelectComponent,
    SelectScheduleConflictComponent,
    SelectSkillConflictComponent,
    SelectAvailabilityConflictComponent,
    SelectAbsenceConflictComponent,
  ],
  exports: declarations,
})
export class SbTableSelectModule {}
